<template>
  <div id="centerBottom">
    <!-- centerBottom -->
    <div class="lb-title">岗位图片</div>
    <div class="swiper-pic">
      <swiper
        :options="swiperOption"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide>
          <div class="lp-img">
            <img src="@/assets/zhen/cun/1.jpeg" alt="" />
            <img src="@/assets/zhen/cun/2.jpeg" alt="" />
            <img src="@/assets/zhen/cun/3.jpeg" alt="" />
            <img src="@/assets/zhen/cun/4.jpeg" alt="" />
            <img src="@/assets/zhen/cun/5.jpeg" alt="" />
            <img src="@/assets/zhen/cun/6.jpeg" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="lp-img">
            <img src="@/assets/zhen/cun/7.jpeg" alt="" />
            <img src="@/assets/zhen/cun/8.jpeg" alt="" />
            <img src="@/assets/zhen/cun/9.jpeg" alt="" />
            <img src="@/assets/zhen/cun/10.jpeg" alt="" />
            <img src="@/assets/zhen/cun/11.jpeg" alt="" />
            <img src="@/assets/zhen/cun/12.jpeg" alt="" />
          </div>
        </swiper-slide>

        <!-- <div class="swiper-pagination"
           slot="pagination"></div>
      <div class="swiper-button-prev"
           slot="button-prev"></div>
      <div class="swiper-button-next"
           slot="button-next"></div> -->
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        // 设置分页器
        // pagination: {
        //   el: '.swiper-pagination',
        //   // 设置点击可切换
        //   clickable: true
        // },
        // // 设置前进后退按钮
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: true,
      },
    }
  },
  methods: {
    onSwiper() {},
    onSlideChange() {},
  },
}
</script>

<style lang="scss" scoped>
$box-height: 430px;
$box-width: 100%;
#centerBottom {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .lb-title {
    width: 100%;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
      0 0 40px #38cefe;
  }
  .swiper-pic {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    // margin-top: -25px;
    .lp-img {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      // margin-top: -10px;
      img {
        width: 32%;
        padding: 5px 0px;
        height: 180px;
      }
    }
  }
}
</style>
