<template>
  <div id="leftBottom">
    <div class="lb-title">残疾人就业情况</div>
    <dv-scroll-board class="lb-content" :config="config" @click="skipTo" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ["村 / 社区", "累计在职人数", "在职人数"],

        // 吉寺村、团泉村、局里村、花木村、九渡河村、黄花镇村、东宫村、西台村、黄花城村、撞道口村、石湖峪村、西水峪村、二道关村、杏树台村、庙上村、红庙村
        data: [
          ["九渡河村", 21, 15],
          ["四渡河村", 9, 6],
          ["黄坎村", 20, 12],
          ["吉寺村", 29, 14],
          ["团泉村", 48, 27],
          ["局里村", 14, 8],
          ["花木村", 20, 10],
          ["黄花镇村", 21, 13],
          ["东宫村", 5, 3],
          ["西台村", 6, 5],
          ["黄花城村", 20, 11],
          ["撞道口村", 2, 0],
          ["石湖峪村", 9, 7],
          ["西水峪村", 1, 0],
          ["二道关村", 27, 18],
          ["杏树台村", 2, 1],
          ["庙上村", 1, 1],
          ["红庙村", 2, 2],
        ],

        rowNum: 8, //表格行数
        columnWidth: [100, 180, 150],
        align: ["center"],
        headerHeight: 40,
        headerBGC: "#5689EA", //表头
        oddRowBGC: "", //奇数行
        evenRowBGC: "", //偶数行
        index: true,
      },
    }
  },
  components: {},
  mounted() {},
  methods: {
    skipTo(config) {
      console.log(config)
      this.$router.push({
        path: "/village",
        query: { address: "怀柔区九渡河镇吉寺村" },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 520px;

#leftBottom {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .lb-title {
    width: 100%;
    height: 8%;
    // display: flex;
    // align-items: center;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
      0 0 40px #38cefe;
  }
  .lb-content {
    width: 100%;
    height: 92%;
    cursor: pointer;
  }
}
</style>
