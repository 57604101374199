<template>
  <div id="leftTop">
    <div class="lb-title">培训计划</div>
    <dv-scroll-board class="lb-content" :config="config" @click="skipTo" />
    <!-- <div id="age" style="width:100%;height:50%"></div>
    <div id="edcution" style="width:100%;height:50%"></div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ["培训内容", "培训日期", "场次", "人数"],

        // 吉寺村、团泉村、局里村、花木村、九渡河村、黄花镇村、东宫村、西台村、黄花城村、撞道口村、石湖峪村、西水峪村、二道关村、杏树台村、庙上村、红庙村
        data: [
          ["心理健康讲座", "2023-04-07", 1, 20],
          ["就业技能类：面点制作", "2023-04-08", 1, 20],
          ["就业技能类-茶艺", "2023-04-09", 1, 15],
          ["健康安全讲座-健康管理", "2023-04-10", 1, 25],
          ["健康安全讲座-残疾预防", "2023-04-11", 1, 20],
          ["信息无障碍-信息技术", "2023-04-12", 1, 25],
          ["信息无障碍-残疾相关", "2023-04-13", 1, 25],
          ["健康安全讲座-中医保健", "2023-04-14", 1, 20],
          ["就业技能类：美甲", "2023-04-15", 1, 20],
          ["就业技能类：收纳整理", "2023-04-16", 1, 25],
        ],

        rowNum: 8, //表格行数
        columnWidth: [50, 180, 150],
        align: ["center"],
        headerHeight: 40,
        headerBGC: "#5689EA", //表头
        oddRowBGC: "", //奇数行
        evenRowBGC: "", //偶数行
        index: true,
      },
      ageData: [
        {
          name: "初中以及下",
          value: 129,
        },
        {
          name: "高中",
          value: 24,
        },
        {
          name: "大专及以上",
          value: 6,
        },
      ],
      edcutionData: [
        {
          name: "19-29",
          value: 6,
        },
        {
          name: "30-49",
          value: 83,
        },
        {
          name: "50-60",
          value: 70,
        },
      ],
      color: [
        "#2ca1ff",
        "#0adbfa",
        "#febe13",
        "#65e5dd",
        "#7b2cff",
        "#fd5151",
        "#f071ff",
        "#85f67a",
      ],
    }
  },
  components: {},
  mounted() {
    // this.showAge()
    // this.showEdcution()
  },
  methods: {
    showAge() {
      let ageChart = this.$echarts.init(document.getElementById("age"))
      var max = Math.max(...this.ageData.map((item) => item.value))
      var data = []
      for (var i = 0; i < this.ageData.length; i++) {
        data.push(
          {
            value: this.ageData[i].value,
            name: this.ageData[i].name,
            itemStyle: {
              normal: {
                borderWidth: 0,
                shadowBlur: 10,
                shadowColor: this.color[i],
              },
            },
          },
          {
            value: max * 0.05,
            name: "",
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                color: "rgba(0, 0, 0, 0)",
                borderColor: "rgba(0, 0, 0, 0)",
                borderWidth: 0,
              },
            },
            label: {
              show: false,
            },
            tooltip: {
              show: false,
            },
          }
        )
      }

      let option = {
        color: this.color,
        title: {
          text: "残疾人受教育程度",
          // left: 'center',
          textStyle: {
            color: "rgb(158, 191, 232)",
          },
          top: 4,
        },
        tooltip: {
          show: true,
          confine: true,
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: "",
            type: "pie",
            clockWise: false,
            radius: ["40%", "60%"],
            hoverAnimation: false,
            center: ["50%", "60%"],
            itemStyle: {},
            label: {
              show: true,
              formatter: "{b}\n{d}%",
              textStyle: {
                fontSize: 16,
                color: "#fff",
              },
            },
            data: data,
          },
        ],
      }
      ageChart.setOption(option)
      ageChart.setOption(option)
      window.addEventListener("resize", function() {
        ageChart.resize() //下面可以接多个图
      })
    },
    showEdcution() {
      let myChart = this.$echarts.init(document.getElementById("edcution"))
      var max = Math.max(...this.edcutionData.map((item) => item.value))
      var data = []
      for (var i = 0; i < this.edcutionData.length; i++) {
        data.push(
          {
            value: this.edcutionData[i].value,
            name: this.edcutionData[i].name,
            itemStyle: {
              normal: {
                borderWidth: 0,
                shadowBlur: 10,
                shadowColor: this.color[i],
              },
            },
          },
          {
            value: max * 0.05,
            name: "",
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                color: "rgba(0, 0, 0, 0)",
                borderColor: "rgba(0, 0, 0, 0)",
                borderWidth: 0,
              },
            },
            label: {
              show: false,
            },
            tooltip: {
              show: false,
            },
          }
        )
      }

      let option = {
        color: this.color,
        title: {
          text: "残疾人就业年龄段",
          // left: 'center',
          textStyle: {
            color: "rgb(158, 191, 232)",
          },
          top: 0,
        },
        tooltip: {
          show: true,
          confine: true,
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: "",
            type: "pie",
            clockWise: false,
            radius: ["40%", "60%"],
            hoverAnimation: false,
            center: ["50%", "60%"],
            itemStyle: {},
            label: {
              show: true,
              formatter: "{b}岁\n{d}%",
              textStyle: {
                fontSize: 16,
                color: "#fff",
              },
            },
            data: data,
          },
        ],
      }
      myChart.setOption(option)
      window.addEventListener("resize", function() {
        myChart.resize() //下面可以接多个图
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 520px;

#leftTop {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;

  .lb-title {
    width: 100%;
    height: 8%;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
      0 0 40px #38cefe;
  }
  .lb-content {
    width: 100%;
    height: 91%;
    cursor: pointer;
  }
}
</style>
