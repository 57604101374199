<template>
  <div id="rigthTop">
    <div id="offline" class="protal"></div>

    <div class="zhen">
      <div v-for="(item, index) in zhenData" :key="index" class="people">
        <div class="name">{{ item.name }}</div>
        <div class="value">
          {{ item.value }}<span class="unit">{{ item.unit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"

export default {
  data() {
    return {
      config: {
        data: [
          {
            name: "生态环境管护员",
            value: 65,
          },
          {
            name: "后勤保障员",
            value: 50,
          },
          {
            name: "疫情管控员",
            value: 44,
          },
        ],
        colors: ["#e062ae", "#32c5e9", "#96bfff"],
        unit: "人",
        showValue: true,
      },

      zhenData: [
        {
          name: "本月招聘",
          value: 0,
          unit: "人",
        },
        {
          name: "本月到期(转签)",
          value: 2,
          unit: "人",
        },
        {
          name: "运营跟进",
          value: "6 / 18",
          unit: "件",
        },
        {
          name: "小程序绑定",
          value: 153,
          unit: "人",
        },
      ],

      //
    }
  },
  components: {},
  mounted() {
    this.OfflineOptions()
  },
  methods: {
    OfflineOptions() {
      var offlinechartDom = document.getElementById("offline")
      var offlineChart = echarts.init(offlinechartDom)

      var scaleData = [
        {
          name: "1-3年",
          value: 13,
        },
        {
          name: "3年以上",
          value: 143,
        },
        {
          name: "新进",
          value: 3,
        },
      ]

      var rich = {
        white: {
          color: "#ddd",
          align: "center",
          padding: [5, 0],
        },
      }
      var placeHolderStyle = {
        normal: {
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          color: "rgba(0, 0, 0, 0)",
          borderColor: "rgba(0, 0, 0, 0)",
          borderWidth: 0,
        },
      }
      var data = []
      for (var i = 0; i < scaleData.length; i++) {
        data.push(
          {
            value: scaleData[i].value,
            name: scaleData[i].name,
            itemStyle: {
              normal: {
                borderWidth: 5,
                shadowBlur: 30,
                borderColor: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                  {
                    offset: 0,
                    color: "#7777eb",
                  },
                  {
                    offset: 1,
                    color: "#70ffac",
                  },
                ]),
                shadowColor: "rgba(142, 152, 241, 0.6)",
              },
            },
          },
          {
            value: 4,
            name: "",
            itemStyle: placeHolderStyle,
          }
        )
      }
      var seriesObj = [
        {
          name: "",
          type: "pie",
          clockWise: false,
          radius: ["40%", "60%"],
          hoverAnimation: false,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "outside",
                color: "#ddd",
                formatter: function(params) {
                  var percent = 0
                  var total = 0
                  for (var i = 0; i < scaleData.length; i++) {
                    total += scaleData[i].value
                  }
                  percent = ((params.value / total) * 100).toFixed(0)
                  if (params.name !== "") {
                    return params.name + "\n{white|" + "占比" + percent + "%}"
                  } else {
                    return ""
                  }
                },
                rich: rich,
              },
              labelLine: {
                show: false,
              },
            },
          },
          data: data,
        },
      ]
      var option = {
        title: {
          text: "就业稳定情况",
          // left: 'center',
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
          top: 4,
        },
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        series: seriesObj,
      }
      offlineChart.setOption(option)
      window.addEventListener("resize", function() {
        offlineChart.resize() //下面可以接多个图
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 520px;

#rigthTop {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .lb-title {
    width: 100%;
    height: 8%;
    // display: flex;
    // align-items: center;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
      0 0 40px #38cefe;
  }
  .lb-content {
    width: 100%;
    height: 43%;
  }
}

::v-deep .dv-capsule-chart .capsule-item div {
  height: 12px;
}
::v-deep .dv-capsule-chart .capsule-item {
  height: 14px;
}
::v-deep .dv-capsule-chart .label-column {
  font-size: 16px;
  margin-top: 5px;
}
::v-deep .dv-capsule-chart .unit-label {
  font-size: 16px;
}
::v-deep
  .dv-capsule-chart
  .capsule-item
  .capsule-item-column
  .capsule-item-value {
  font-size: 14px;
}

.protal {
  width: 100%;
  height: 50%;
}
.zhen {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .people {
    width: 48%;
    margin-bottom: 10px;
    padding: 20px 0;
    background-color: rgba(86, 137, 234, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .name {
      color: #fff;
    }
    .value {
      margin-top: 20px;
      font-size: 28px;
      color: #3de4c8;
    }
    .unit {
      color: #fff;
      font-size: 16px;
    }
  }
}
</style>
