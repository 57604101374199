<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title" @click="titleClick()">
              <!-- <span class="title-text">淳安县残疾人帮扶性就业试点看板</span> -->
              <span class="title-text">{{ address }}</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <div class="body-box" style="align-items: center;">
          <div class="vallage-left">
            <dv-border-box-12>
              <leftTopOne />
            </dv-border-box-12>
            <dv-border-box-13>
              <rigthTop />
            </dv-border-box-13>

            <dv-border-box-13>
              <leftTop />
            </dv-border-box-13>
          </div>

          <div class="vallage-right" style="margin-top:20px">
            <div class="left">
              <dv-border-box-13> <leftBottom /></dv-border-box-13>
            </div>
            <div class="right">
              <dv-border-box-13>
                <rightBottom />
              </dv-border-box-13>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin"
import { formatTime } from "../../utils/index.js"

import leftBottom from "./components/leftBottom.vue"
import rightBottom from "./components/rightBottom.vue"

import leftTop from "./components/leftTop.vue"
import rigthTop from "./components/rightTop.vue"
import leftTopOne from "./components/leftTopOne.vue"

export default {
  components: { leftBottom, rightBottom, leftTop, rigthTop, leftTopOne },
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      address: "",
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#568aea"],
    }
  },

  mounted() {
    this.timeFn()
    this.cancelLoading()
    this.address = this.$route.query.address
    // console.log(address, "-----")
  },

  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    titleClick() {
      this.$router.go(-1)
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss")
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd")
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/chunan.scss";
.bg {
  background-image: linear-gradient(#022165, #0252c3) !important;
}
.bg-color-black {
  background-color: rgba(13, 28, 83, 0.3) !important;
}
.body-box {
  display: flex;
  flex-direction: column;
}
.vallage-left {
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.vallage-right {
  width: 95%;
  display: flex;

  .left {
    width: 50%;
  }
  .right {
    width: 50%;
  }
}
</style>
